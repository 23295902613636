import React from "react"
import { Container } from "./styled"

const OptionSelect = ({ name, id, data, value, update }) => {
  return (
    <Container>
      <select
        name={name}
        id={name}
        defaultValue={value || ""}
        onChange={e => update(name, id, e.target.value)}
      >
        {data.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          )
        })}
      </select>
    </Container>
  )
}

export default OptionSelect
