import styled from "styled-components"

export const Container = styled.div`
  & > div {
    margin: 0 auto;
    width: 20%;
    padding-top: 15px;
    padding-bottom: 20px;

    @media (max-width: 650px) {
      width: 60%;
    }

    & > img {
    }
  }
`
