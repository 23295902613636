import styled from "styled-components"

export const Container = styled.div`
  padding-left: 15px;
  width: 17.5%;

  @media (max-width: 650px) {
    padding-top: 15px;
    padding-left: 0;
    width: 50%;
  }

  & > select {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0NDQ0NDQ7fQo8L3N0eWxlPgo8dGl0bGU+YXJyb3dzPC90aXRsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxLjQsNC43IDIuNSwzLjIgMy41LDQuNyAiLz4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4KPC9zdmc+Cg==)
      no-repeat 99.5% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    display: inline-block;
    height: 30px;
    margin: 0;
    padding: 0 7.5px;
    background-color: #fff;
    border: 1px solid var(--border);
    border-radius: 4px;
    vertical-align: middle;
    font-size: 16px;
    outline: none;
    /*box-shadow: 0 1px 3px 0 var(--lightgrey);*/
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    &:focus {
      border-color: var(--border);
    }
  }
`
