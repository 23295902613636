import styled from "styled-components"

export const Container = styled.button`
  width: auto;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  width: auto;
  background: var(--gold);
  padding: 10px 20px;
  border: 1px solid var(--gold);
  border-radius: 0;
  color: #fff;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: background 0.5s ease, color 0.5s ease;
  outline: none;

  &.smaller {
    background: #fff;
    color: var(--gold);
    width: 100%;
    padding: 7.5px 15px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  &:hover {
    background-color: var(--gold);
    color: #fff;
  }

  &.smaller:hover {
    background: #fff;
    color: var(--gold);
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > span > span {
    padding-right: 10px;
  }
`
