import styled from "styled-components"

export const Container = styled.div`
  margin-top: 0;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.hidden {
    display: none;
  }

  & > button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    color: var(--gold);
  }

  & > button:hover {
    color: var(--red);
  }

  & > button > span {
    display: flex;
    align-items: center;

    & > span {
      padding-left: 7.5px;
    }
  }
`
