import React, { useState, Fragment } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE_KEY } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Logo from "../components/index/Logo"
import Order from "../components/index/Order"
import Payment from "../components/index/Payment"
import Receipt from "../components/index/Receipt"
import Footer from "../components/index/Footer"

const stripePromise = loadStripe(STRIPE_KEY)

const Index = () => {
  const [started, setStarted] = useState(0)
  const [submitted, setSubmitted] = useState(null)

  const handleStarted = status => {
    setStarted(status)
  }

  const handleSubmitted = obj => {
    setSubmitted(obj)
  }

  return (
    <Layout>
      <Helmet
        index={true}
        descr="Order from our regular menu using touch free takeout with a twist."
      />
      <Logo />
      <Fragment>
        {started && submitted ? (
          <Receipt details={submitted} />
        ) : started ? (
          <Payment
            stripePromise={stripePromise}
            started={handleStarted}
            submitted={handleSubmitted}
          />
        ) : (
          <Order started={handleStarted} />
        )}
      </Fragment>
      <Footer />
    </Layout>
  )
}

export default Index
