import styled from "styled-components"

export const Container = styled.button`
    width: auto;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    width: auto;
    background: var(--gold);
    padding: 10px 20px;
    border: 1px solid var(--gold);
    border-radius: 0;
    color: #fff;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 16px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: background 0.5s ease, color 0.5s ease;
    outline: none;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  &:hover {
    color: #fff;
    background: var(--gold);
    border-color: var(--gold);
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > span > span {
    padding-right: 10px;
  }

  &.back {
    padding: 7.5px 15px;
    margin-left: 1px;
    min-width: 0px;
    font-size: 16px;
    line-height: 16 dpx;
    font-weight: normal;
    color: var(--gold);
    background-color: #fff;
    margin-bottom: 15px;
  }

  &.back:hover {
    background-color: var(--gold);
    color: #fff;
  }

  &.back > span > span {
    padding-right: 0;
    padding-left: 10px;
  }
`
