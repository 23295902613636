import styled from "styled-components"

export const Container = styled.div`
  padding: 25px 15px;
  background-color: var(--gold);

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    & > div {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > p {
        line-height: 26px;
        margin-top: 7.5px;
        margin-bottom: 15px;
        text-align: center;
      }

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;

        & > a:nth-child(2) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    & > div:nth-child(2) {
      @media (max-width: 768px) {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }

  & a {
    color: #fff;
  }

  & a:hover {
    color: var(--lightgold);
  }

  & > div.logo {
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 15px;
    width: calc(100% - 25%);
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #fff;

    @media (max-width: 768px) {
      width: calc(100% - 15px);
    }

    & > img {
      width: 25%;

      @media (max-width: 1080px) {
        width: 35%;
      }

      @media (max-width: 650px) {
        width: 50%;
      }
    }
  }

  & > p.copy {
    font-size: 0.9rem;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
  }
`
